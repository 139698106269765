<template>
  <div class="transCost articleBox">
    <div class="m-nav">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="container">
        <el-breadcrumb-item :to="{ path: '/business/securities' }">{{
          $t("business.transCost.navFirst")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/business/securities' }">
          {{ $t("business.transCost.navSecond") }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{
          $t("business.transCost.navThird")
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="flex container articleContent">
      <div class="left-nav">
        <div
          class="nav-item"
          v-for="item in $t('business.transCost.leftNavData')"
          :key="item.id"
        >
          <div class="item-title">{{ item.name }}</div>
          <ul>
            <li
              @click="getTableInfo(child.id)"
              v-for="child in item.children"
              :key="child.id"
              :class="[child.id === market ? 'active' : '']"
            >
              {{ child.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="right-table flex1" v-loading="loading">
        <div
          class="entry-content"
          v-for="(data, index) in tableData"
          :key="index"
        >
          <h3>{{ index + 1 + "、" + data.cate_name }}</h3>
          <table width="100%" border="1" cellpadding="0" align="center">
            <thead>
              <tr>
                <th width="140">{{ $t("business.transCost.tableHead[0]") }}</th>
                <th>{{ $t("business.transCost.tableHead[1]") }}</th>
                <th width="278">{{ $t("business.transCost.tableHead[2]") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in data.list" :key="index + idx">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.explain }}
                </td>
                <td>
                  {{ item.owner }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
export default {
  data() {
    return {
      tableData: [],
      market: "hk",
      loading: false,
    };
  },
  created() {
    this.getList(this.market);
  },
  methods: {
    getTableInfo(market) {
      this.market = market;
      this.getList(market);
    },
    getList(market) {
      if (!market) return;
      this.loading = true;
      api.common
        .qksFeeList({ market })
        .then((res) => {
          const list = this.splitKind(res);
          this.tableData = list;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    splitKind(arr) {
      let dataArr = [];
      arr.map((mapItem) => {
        if (dataArr.length == 0) {
          dataArr.push({
            cate_id: mapItem.cate_id,
            cate_name: mapItem.cate_name,
            list: [mapItem],
          });
        } else {
          let res = dataArr.some((item) => {
            //判断相同日期，有就添加到当前项
            if (item.cate_id == mapItem.cate_id) {
              item.list.push(mapItem);
              return true;
            }
          });
          if (!res) {
            //如果没找相同日期添加一个新对象
            dataArr.push({
              cate_id: mapItem.cate_id,
              cate_name: mapItem.cate_name,
              list: [mapItem],
            });
          }
        }
      });
      return dataArr;
    },
  },
};
</script>
<style lang="scss" scoped>
.transCost {
  color: var(--blackColor);
  padding-bottom: 80px;
  padding-top: 26px;
  .articleContent {
    .left-nav {
      width: 100px;
      padding-right: 20px;
      border-right: 1px solid var(--borderColor);
      .nav-item {
        .item-title {
          font-size: 18px;
          font-weight: 700;
          text-align: right;
        }
        ul {
          li {
            text-align: right;
            font-size: 14px;

            color: var(--primaryColor);
            margin-top: 10px;
            cursor: pointer;
            &.active {
              color: var(--blue);
            }
            &:hover {
              color: var(--blue);
            }
          }
        }
      }
    }
    .right-table {
      padding-left: 20px;
      padding-top: 10px;
      ::v-deep .el-loading-mask {
        z-index: 500;
      }
    }
  }
  .m-nav {
    z-index: 501;
  }
  table {
    margin-bottom: 20px;
  }
  .entry-content h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .entry-content thead {
    background-color: var(--theadBgColor);
    th {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .entry-content tr {
    height: 50px;
  }
  .entry-content td,
  .entry-content th {
    word-break: break-all;
    border: 1px solid var(--iconGray);
    text-align: center;
    vertical-align: middle;
  }
}
</style>
